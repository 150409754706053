import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'logo_instagram_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="logo_instagram_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M15.096 3c2.01.004 2.428.02 3.44.066 1.17.054 1.97.24 2.67.512a5.392 5.392 0 011.948 1.268 5.392 5.392 0 011.269 1.949c.271.7.457 1.499.51 2.67.047 1.011.063 1.429.066 3.439v2.192c-.003 2.01-.019 2.428-.065 3.44-.054 1.17-.24 1.97-.511 2.67a5.392 5.392 0 01-1.27 1.948 5.392 5.392 0 01-1.948 1.269c-.7.271-1.499.457-2.67.51-1.011.047-1.429.063-3.439.066h-2.192c-2.01-.003-2.428-.019-3.44-.065-1.17-.054-1.97-.24-2.67-.511a5.392 5.392 0 01-1.948-1.27 5.392 5.392 0 01-1.268-1.948c-.272-.7-.458-1.499-.512-2.67-.046-1.011-.062-1.429-.065-3.439v-2.192c.003-2.01.019-2.428.065-3.44.054-1.17.24-1.97.512-2.67a5.392 5.392 0 011.268-1.948 5.392 5.392 0 011.949-1.268c.7-.272 1.499-.458 2.67-.512 1.011-.046 1.429-.062 3.439-.065zm-.524 1.982h-1.144c-2.417.002-2.793.015-3.873.064-1.072.049-1.655.228-2.042.379-.514.2-.88.438-1.265.823a3.408 3.408 0 00-.823 1.265c-.15.387-.33.97-.379 2.042-.05 1.08-.062 1.456-.064 3.873v1.144c.002 2.417.015 2.793.064 3.873.049 1.072.228 1.655.379 2.042.2.514.438.88.823 1.265.385.385.751.624 1.265.823.387.15.97.33 2.042.379 1 .046 1.396.06 3.368.063h2.154c1.972-.003 2.368-.017 3.368-.063 1.072-.049 1.655-.228 2.042-.379.514-.2.88-.438 1.265-.823.385-.385.624-.751.823-1.265.15-.387.33-.97.379-2.042.046-1 .06-1.396.063-3.368v-2.153c-.003-1.973-.017-2.369-.063-3.369-.049-1.072-.228-1.655-.379-2.042-.2-.514-.438-.88-.823-1.265a3.408 3.408 0 00-1.265-.823c-.387-.15-.97-.33-2.042-.379-1.08-.05-1.456-.062-3.873-.064zm-.575 3.375a5.647 5.647 0 110 11.293 5.647 5.647 0 010-11.293zm0 1.981a3.666 3.666 0 100 7.331 3.666 3.666 0 000-7.331zm5.87-3.524a1.32 1.32 0 110 2.64 1.32 1.32 0 010-2.64z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28LogoInstagramProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28LogoInstagram: FC<Icon28LogoInstagramProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28LogoInstagram as any).mountIcon = mountIcon;

export default Icon28LogoInstagram;
