import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'sort_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="sort_outline_24"><path d="M8 6.1a.9.9 0 01.9.9l-.001 7.827 1.465-1.463a.9.9 0 011.18-.08l.092.08a.9.9 0 010 1.272l-3 3a.9.9 0 01-1.272 0l-3-3a.9.9 0 011.272-1.272L7.1 14.827 7.1 7a.9.9 0 01.787-.893zm8.636.264l3 3a.9.9 0 11-1.272 1.272l-1.465-1.464L16.9 17a.9.9 0 11-1.8 0l-.001-7.828-1.463 1.464a.9.9 0 11-1.272-1.272l3-3a.9.9 0 011.272 0z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24SortOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24SortOutline: FC<Icon24SortOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24SortOutline as any).mountIcon = mountIcon;

export default Icon24SortOutline;
